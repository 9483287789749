@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  word-break: keep-all;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
::-webkit-scrollbar { 
  width: 10px; 
}

::-webkit-scrollbar-thumb {
  background: rgba(128, 128, 128, 0.5);
  border-radius: 10px;
}

/* 트랙 (Track) */
::-webkit-scrollbar-track {
    background: white;

}
h2 {
  letter-spacing: -1px;
  margin-top: 8px;
  margin-bottom: 8px;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 5vw;
  }
  h2 {
    margin: 1.5vw;
    font-size: 3vw;
  }
}

body {
  margin: 0;
  font-family: "Noto Sans KR", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.slick-slide > div  {
  height: 100%;
}
.slick-track { 
  display: flex;
}